<template>
  <div class="bg-gray-100 w-screen h-screen flex justify-center items-center px-5 lg:px-10">
    <div class="flex flex-col items-center justify-center bg-white shadow-lg p-7 rounded-xl py-10">
      <img src="@/assets/images/logo.png" width="120px" />
      <div class="mt-5" v-if="!loadingStatus">
        <div v-if="passwordResetComplete">
          <p class="mb-3">
            You password reset was successful, kindly go back to the app and sign in with your new password
          </p>
        </div>
        <div v-else-if="confirmation_token">
          <p class="mb-3">
            You are about to reset the password for
            <span class="font-medium">{{confirmation_token && confirmation_token.email}}</span>
          </p>
          <b-field>
				<b-input type="password"
					id="password"
					v-model="new_password"
					placeholder="New password"
					password-reveal
				>
				</b-input>
			</b-field>

			<b-field>
				<b-input type="password"
					id="password"
					placeholder="Confirm password"
					v-model="confirm_password"
					password-reveal
				>
				</b-input>
			</b-field>

            <div class="mb-4 text-gray-500">
                <p class="flex items-center">
                    <Check v-if="is8characters" :width="18" />
                    <span class="ellipse" v-else />
                    <span class="pl-1">
                        Password must contain at least 8 characters
                    </span>
                </p>
                <p class="flex items-center">
                    <Check v-if="isAlphanumeric" :width="18" />
                    <span class="ellipse" v-else />
                    <span class="pl-1">
                        Password must contain alphanumeric characters
                    </span>
                </p>
                <p class="flex items-center">
                    <Check v-if="isPasswordMatch" :width="18" />
                    <span class="ellipse" v-else />
                    <span class="pl-1">
                        Password must match
                    </span>
                </p>
            </div>

            <div class="text-center mt-5">
                <button
                    class="button is-primary text-white font-bold px-5 py-2 rounded-md"
                    :class="resetPasswordLoading? 'is-loading': ''"
                    @click="handleResetPassword()"
                    :disabled="resetPasswordLoading || !(is8characters && isAlphanumeric && isPasswordMatch) "
                >
                    Reset password
                </button>
            </div>
        </div>

        <div v-else>
          <p class="mb-3">
            The session is expired or invalid
          </p>
        </div>
      </div>
      <div v-else>
        <loader />
      </div>
    </div>
  </div>
</template>

<script>
import {verifyToken, resetPassword} from "@/api/auth";
import Loader from "../../svg/Loader.vue";
import Check from "../../svg/SuccessCheck";
export default {
  components: { Loader, Check },
  name: 'ResetPassword',
  data() {
    return {
      new_password: '',
      confirm_password: '',
      confirmation_token: null,
      loadingStatus: false,
      resetPasswordLoading: false,
      passwordResetComplete: false
    }
  },

  computed: {
		is8characters(){
			if (this.new_password.length > 7) {
				return true;
			}
		},
		isAlphanumeric(){
			const letterRegex = /^(?=.*[a-zA-Z])(?=.*[0-9])/;
			if (this.new_password.match(letterRegex)) {
				return true;
			}
		},
		isPasswordMatch(){
			if ((this.new_password == this.confirm_password) && this.is8characters) {
			return true;
			}
		}
	},

  methods: {
    async verifyTokenValidity() {
      try {
        this.loadingStatus = true;
        const {token} = this.$route.query;
        if(!token) {
          this.confirmation_token = null;
          return;
        }
        const response = await verifyToken({
          userToken: token,
        })
        this.loadingStatus = false;
        if (response.data?.success) {
          this.confirmation_token = response.data?.data;
        } else {
          this.confirmation_token = null;
        }
      } catch (err) {
        this.loadingStatus = false;
      }
    },
    async handleResetPassword() {
      try {
        const letterRegex = /^(?=.*[a-zA-Z])(?=.*[0-9])/;
        if (this.new_password.length < 8) {
          this.$toast.warning("Your password must contain at least 8 characters");
          return;
        } else if (!this.new_password.match(letterRegex)) {
          this.$toast.warning("Your password must contain at least one alphabet and one numeric character");
          return;
        } else if (this.new_password !== this.confirm_password) {
          this.$toast.warning("Your password does not match");
          return;
        }
        this.resetPasswordLoading = true;
        const {token} = this.$route.query;
        if(!token) {
          this.passwordResetComplete = false;
          return;
        }
        const response = await resetPassword({
          user_token: token,
          new_password: this.new_password
        }, this.confirmation_token.userType)
        this.resetPasswordLoading = false;
        if (response.data?.success) {
          this.$toast.success("Your password reset was successful");
          this.passwordResetComplete = true;
        } else {
          this.$toast.error("Your password reset was not successful");
          this.passwordResetComplete = false;
        }
      } catch (err) {
        this.resetPasswordLoading = false;
        this.loadingStatus = false;
      }
    }
  },
  mounted() {
    this.verifyTokenValidity();
  }
}
</script>

<style>
.ellipse{
    display: block;
    width: 14px;
    height: 14px;
    margin: 0px 1px;
    border-radius: 999px;
    border: 0.5px solid rgb(97, 97, 97);
}
.icon.is-clickable.has-text-primary {
	color: gray !important;
}
#password.input:focus{
	box-shadow: none !important;
	border-color: #a2a2a2 !important;
}</style>