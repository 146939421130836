<template>
    <div>
        <div class="h-screen flex items-center" v-if="loadingStatus">
            <div class="m-auto">
                <Ripple :isLoading="loadingStatus" />
            </div>
        </div>
    
        <div v-else class="bg-white lg:mx-60">
            <div v-if="activeTab == 0">
                <div class="flex justify-between items-center mb-12 pr-4 lg:pr-12">
                    <div class="w-5/12 lg:w-4/12">
                        <img src="../../assets/images/letterhead_logo.png">
                    </div>
                    
                    <div class="">
                        <span class="text-black text-xs md:text-sm">
                            Offer letter {{ loan ? loan.contract_status : '' }}
                        </span>
                        <p class="text-xs md:text-sm">RC : 1505897</p>
                    </div>
                </div>

                <div class="md:w-10/12 m-auto">
                    <div class="mt-12 px-4 lg:px-12">
                        <div class="mb-6">
                            <p class="text-xs lg:text-sm">
                                {{ loan ? String(loan.loan_id).toUpperCase() : '' }}
                            </p>
                            <p class="text-xs lg:text-sm">
                                {{ loan.Customer ? loan.Customer.address : '' }}
                            </p>
                            <p class="text-xs lg:text-sm font-bold">
                                Hello {{ loan.Customer ? loan.Customer.name : '' }},
                            </p>
                        </div>

                        <div class="mb-6">
                            <h3 class="pb-4 text-sm font-semibold">OFFER LETTER</h3>
                            <ul class="">
                                <li class="flex justify-between text-md pb-2 lg:px-6 mb-2 text-xs lg:text-sm border-b-2 text-gray-500">
                                    <span class="text-black">Borrower Name</span>
                                    <span>{{ loan.Customer ? loan.Customer.name : '' }}</span>
                                </li>
                                <li class="flex justify-between text-md pb-2 lg:px-6 mb-2 text-xs lg:text-sm border-b-2 text-gray-500">
                                    <span class="text-black">Principal</span>
                                    <span>₦{{ loan.amount_requested ? Number(loan.amount_requested || 0).toLocaleString('en-NG', {minimumFractionDigits: 2, maximumFractionDigits: 2}) : 'None'}}</span>
                                </li>
                                <li class="flex justify-between text-md pb-2 lg:px-6 mb-2 text-xs lg:text-sm border-b-2 text-gray-500">
                                    <span class="text-black">Interest Rate</span>
                                    <span>{{ loan.rate }}%</span>
                                </li>
                                <li class="flex justify-between text-md pb-2 lg:px-6 mb-2 text-xs lg:text-sm border-b-2 text-gray-500">
                                    <span class="text-black">Total Interest</span>
                                    <span>₦{{ Number(total_interest || 0).toLocaleString('en-NG', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</span>
                                </li>
                                <li class="flex justify-between text-md pb-2 lg:px-6 mb-2 text-xs lg:text-sm border-b-2 text-gray-500">
                                    <span class="text-black">Amount disbursed</span>
                                    <span>₦{{ Number(loan.amount_disbursed).toLocaleString('en-NG', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</span>
                                </li>

                                <li class="flex justify-between text-md pb-2 lg:px-6 mb-2 text-xs lg:text-sm border-b-2 text-gray-500">
                                    <span class="text-black">Total repayment</span>
                                    <span>₦{{ Number((total_principal + total_interest) || 0).toLocaleString('en-NG', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</span>
                                </li>

                                <li class="flex justify-between text-md pb-2 lg:px-6 mb-2 text-xs lg:text-sm border-b-2 text-gray-500">
                                    <span v-if="loan.duration_type == 'day'" class="text-black">Daily Installments</span>
                                    <span v-if="loan.duration_type == 'week'" class="text-black">Weekly Installments</span>
                                    <span v-if="loan.duration_type == 'month'" class="text-black">Monthly Installments</span>
                                    <span>₦{{ first_schedule? Number((first_schedule.interestTobePaid + first_schedule.principalTobePaid) || 0).toLocaleString('en-NG', {minimumFractionDigits: 2, maximumFractionDigits: 2}) : 'None' }}</span>
                                </li>

                                <li class="flex justify-between text-md pb-2 lg:px-6 mb-2 text-xs lg:text-sm border-b-2 text-gray-500">
                                    <span class="text-black">Duration</span>
                                    <span>{{ `${loan.duration} ${loan.duration_type}s` }}</span>
                                </li>
                                <li class="flex justify-between text-md pb-2 lg:px-6 mb-2 text-xs lg:text-sm border-b-2 text-gray-500">
                                    <span class="text-black">Maturity Date</span>
                                    <span>{{ maturity_date == '' ? 'N/A' : moment(maturity_date).format('MMM Do, YYYY') }}</span>
                                </li>
                                <li class="flex justify-between text-md pb-2 lg:px-6 mb-2 text-xs lg:text-sm border-b-2 text-gray-500">
                                    <span class="text-black">Disbursement Date</span>
                                    <span>{{ loan.disbursement_date == null ? 'N/A' : moment(loan.disbursement_date).format('MMM Do, YYYY') }}</span>
                                </li>

                                <li class="flex justify-between text-md pb-2 lg:px-6 mb-2 text-xs lg:text-sm border-b-2 text-gray-500">
                                    <span class="text-black">Date created</span>
                                    <span>{{ moment(loan.createdAt).format('MMM Do, YYYY') }} {{ new Date(loan.createdAt).toLocaleTimeString(
                                'en-GB', { hour: "numeric",  minute: "numeric"}) }}</span>
                                </li>
                                <li class="flex justify-between text-md pb-2 lg:px-6 mb-2 text-xs lg:text-sm border-b-2 text-gray-500">
                                    <span class="text-black">Date updated</span>
                                    <span>{{ moment(loan.updatedAt).format('MMM Do, YYYY') }} {{ new Date(loan.updatedAt).toLocaleTimeString(
                                'en-GB', { hour: "numeric",  minute: "numeric"}) }}</span>
                                </li>

                                <li class="flex justify-between text-md pb-2 lg:px-6 mb-2 text-xs lg:text-sm border-b-2 text-gray-500">
                                    <span class="text-black">Date decided on</span>
                                    <span v-if="loan.contract_decided_on == null">Not decided</span>
                                    <span v-else>{{ moment(loan.contract_decided_on).format('MMM Do, YYYY') }} {{ new Date(loan.contract_decided_on).toLocaleTimeString(
                                'en-GB', { hour: "numeric",  minute: "numeric"}) }}</span>
                                </li>

                                <li class="flex justify-between text-md pb-2 lg:px-6 mb-2 text-xs lg:text-sm border-b-2 text-gray-500">
                                    <span class="text-black">First Repayment Date</span>
                                    <span>{{ first_schedule? moment(first_schedule.dueDate).format('MMM Do, YYYY'): 'None' }}</span>
                                </li>
                            </ul>
                        </div>

                        <div v-if="loan.DisbursementDeductions.length > 0" class="mb-6">
                            <h3 class="pb-4 text-sm font-semibold">Loan Deductions</h3>

                            <div class="overflow-x-auto pb-2">
                                <table >
                                    <tr class="text-xs lg:text-sm">
                                        <th>Description</th>	
                                        <th>Amount</th>
                                    </tr>

                                    <tr v-for="(deduction, index) in loan.DisbursementDeductions" :key="index" class="text-xs lg:text-sm">		
                                        <td>{{deduction.description}}</td>
                                        <td>-₦{{ Number(deduction.amount || 0).toLocaleString('en-NG', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</td>
                                    </tr>
                                </table>
                            </div>
                        </div>

                        <div v-if="loan.duration_type == 'month'" class="mb-6">
                            <h3 class="pb-4 text-sm font-semibold">REPAYMENT SCHEDULE</h3>

                            <div class="overflow-x-auto pb-2">
                                <table >
                                    <tr class="text-xs lg:text-sm">
                                        <th>Due date</th>
                                        <th>Opening balance</th>
                                        <th>Principal</th>
                                        <th>Interest</th>
                                        <th>Repayment</th>	
                                        <th>Close balance</th>			
                                        <th>Paid</th>			
                                    </tr>

                                    <tr v-for="(schedule, index) in repayment_schedules" :key="index" class="text-xs lg:text-sm">		
                                        <td>{{schedule.dueDate? moment(schedule.dueDate).format('MMM Do, YYYY'): 'None'}}</td>
                                        <td>₦{{ schedule.openingBalance? Number(schedule.openingBalance || 0).toLocaleString('en-NG', {minimumFractionDigits: 2, maximumFractionDigits: 2}): 'None' }}</td>

                                        <td>₦{{schedule.principalTobePaid? Number(schedule.principalTobePaid || 0).toLocaleString('en-NG', {minimumFractionDigits: 2, maximumFractionDigits: 2}) : 'None' }}</td>
                                        <td>₦{{schedule.interestTobePaid?  Number(schedule.interestTobePaid || 0).toLocaleString('en-NG', {minimumFractionDigits: 2, maximumFractionDigits: 2}) : 'None' }}</td>

                                        <td>₦{{ Number((schedule.principalTobePaid + schedule.interestTobePaid) || 0).toLocaleString('en-NG', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</td>
                                        <td>₦{{ Number(schedule.closingBalance || 0).toLocaleString('en-NG', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</td>
                                        <td>₦{{ Number(schedule.amountPaid || 0).toLocaleString('en-NG', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</td>
                                    </tr>
                                </table>
                            </div>
                        </div>

                        <div class="mb-6">
                            <h3 class="pb-4 text-sm font-semibold">GENERAL TERMS AND CONDITIONS</h3>
                            <div>
                                <ol class="list-outside list-decimal text-xs lg:text-sm">
                                    <li>
                                        <h3 class="text-md font-semibold pb-2">Advance and Repayment</h3>
                                        <ol class="ml-6 list-outside list-decimal space-y-3">
                                            <li>
                                                Sycamore Integrated Solutions Limited ("Sycamore") agrees to advance the Sycamore Facility and the Obligor agrees to take the Sycamore Facility subject to the terms and conditions as set out in the Sycamore Facility application form and also herein in this offer letter.
                                            </li>

                                            <li>
                                                The Obligor agrees to make repayments on the Sycamore Facility (principal plus accrued interest) in accordance with the terms and conditions of this letter.
                                            </li>

                                            <li>
                                                The Obligor agrees that Sycamore has the right to initiate repayment three days earlier than the stipulated date of repayment.
                                            </li>

                                            <li>
                                                First Month’s Interest Payment: Where the loan is liquidated (paid off) within the first month of its term, the obligor shall pay the entire amount of interest due for that first month, regardless of the exact date of liquidation within that month
                                            </li>

                                            <!-- <li>
                                                Early Liquidation Fee Structure: Where the Obligor repays the loan early, the obligor agrees to the following terms of repayment. The first month shall be exclusive from the beginning of the tenor.
                                            </li>

                                            <li>
                                                Each loan tenor shall be divided and prorated into 4 classes/categories for the duration of the loan lifespan.i.e where a loan is for 12 months: it will be classified in the following format: 
                                                <ol class="list-outside list-style-alpha px-5 mt-2 space-y-2">
                                                    <li>
                                                        The first month.
                                                    </li>
                                                    <li>
                                                        the second to  third month (termed as the Beginning of the Tenor)
                                                    </li>
                                                    <li>
                                                        the fourth to eight month (termed as the Middle of the Tenor)
                                                    </li>
                                                    <li>the ninth to the twelfth month (termed, Towards the End of the Tenor)</li>
                                                </ol>
                                            </li>

                                            <li>
                                                All loans will be divided into four categories where the tenor is three months each loan will be classified monthly in the manner below. Hence all loans will be grouped exclusively of the first month into three.

                                                <ol class="list-outside list-style-roman px-5 mt-2 space-y-2">
                                                    <li>
                                                        Beginning of the Tenor:
                                                        <ol class="list-outside list-style-alpha px-5 mt-2 space-y-2">
                                                            <li>Fee: 10%  of the outstanding balance will be deducted as early liquidation fee.</li>
                                                            <li>Description: shall apply where the loan is repaid at the midpoint of the loan term.</li>
                                                        </ol>
                                                    </li>
                                                    <li>
                                                        Middle of the Tenor:
                                                        <ol class="list-outside list-style-alpha px-5 mt-2 space-y-2">
                                                            <li>Fee: 5% of the outstanding balance will be deducted as early liquidation fee</li>
                                                            <li>Description: shall apply when the loan is repaid at the very beginning of the loan term.</li>
                                                        </ol>
                                                    </li>
                                                    <li>
                                                        Towards the End of the Tenor:
                                                        <ol class="list-outside list-style-alpha px-5 mt-2 space-y-2">
                                                            <li>Fee: 2% of the outstanding balance will be deducted as early liquidation fee.</li>
                                                            <li>Description: shall apply where the loan is repaid closer to the end of the loan term.</li>
                                                        </ol>
                                                    </li>
                                                </ol>
                                            </li> -->
                                        </ol>
                                    </li>

                                    <li>
                                        <h3 class="text-md font-semibold pt-6 pb-2">Interest</h3>
                                        <ol class="ml-6 list-outside list-decimal space-y-3">
                                            <li>
                                                The monthly repayment is based on a daily interest accrual and calculated on the basis of payment at regular agreed intervals. If the due date for a repayment falls on a non-business day, then the repayment may be paid on the next succeeding business day. Additional interest may accrue in such cases.
                                            </li>

                                            <li>
                                                Sycamore will calculate interest on the daily balance outstanding.
                                            </li>

                                            <li>
                                                Where the Obligor fails to pay Sycamore the repayment by the due date, daily interest will accrue on the total unpaid sum and Sycamore Integrated Services will take collection measures to retrieve the amount due.
                                            </li>

                                            <li>
                                                Sycamore will apply default interest rate from the date on which the Obligor was due to pay the sum.
                                            </li>

                                            <li>
                                                The default interest rate:
                                                <ol class="list-outside list-disc pl-6">
                                                    <li>
                                                        Charged by Sycamore in addition to the normal interest rate which it charges the Obligor at the same time and in the same manner as that normal interest.
                                                    </li>
                                                    <li>
                                                        Accrues before and after any court judgment, Sycamore obtains against the Obligor.
                                                    </li>
                                                    <li>
                                                        Is liquidated damages to compensate Sycamore for the additional risk and other administrative costs and expenses associated with the Obligor’s failure to pay the outstanding sum.
                                                    </li>
                                                </ol>
                                            </li>
                                            <li>
                                                First month Interest Liquidation:
                                                <ol class="list-outside list-disc pl-6 space-y-1 mt-1">
                                                    <li>
                                                        Interest shall be always payable in full were liquidated within the first month. And all early liquidation shall vary based on when the loan is repaid within the term, ranging from 10% at the beginning to 2% towards the end. 
                                                    </li>
                                                </ol>
                                            </li>
                                        </ol>
                                    </li>

                                    <li>
                                        <h3 class="text-md font-semibold pt-6 pb-2">Interest Fee</h3>
                                        <p class="leading-6">
                                            The Obligor agrees to a 1% risk-based interest fee per disbursal which will be taken upfront to provide a financial credit risk cover on the loan. The Obligor gives Sycamore the right to deduct the amount owing to the fee upfront. The Obligor agrees that Sycamore is irrevocably authorized to comply with any instruction on the service received through any of Sycamore's digital channels and it is agreed that such instruction shall be irrevocably deemed to be the Obligor instructions issued on his/her behalf. Our rates are between 5%-13% subject to loan tenor, size and type.
                                        </p>
                                    </li>

                                    <li>
                                        <h3 class="text-md font-semibold pt-6 pb-2">Representations and Warranties</h3>
                                            The Obligor represents and warrants that:
                                        <ol class="ml-6 list-outside list-decimal space-y-3">
                                            <li>
                                                He/she has the right to accept this facility and has taken all necessary actions to authorize the same upon the terms and conditions herein.
                                            </li>
                                            <li>
                                                He/she is not in default under any obligation in respect of any borrowed money that the acceptance of this facility will be or result in breach of or default under any provision of any other agreement to which the Obligor is a party.
                                            </li>

                                            <li>
                                                The information given to Sycamore verbally or in writing in the formal Sycamore Facility application form is true and accurate.
                                            </li>

                                            <li>
                                                He/she will use the Sycamore Facility for the purpose(s) they told Sycamore of in their application for the Sycamore Facility.
                                            </li>
                                        </ol>
                                    </li>
                                    
                                    <li>
                                        <h3 class="text-md font-semibold pt-6 pb-2">Events of Default</h3>
                                            The occurrence of any of the following events shall cause the outstanding amount under this facility to become immediately due and payable:
                                        <ol class="ml-6 list-outside list-decimal space-y-3">
                                            <li>
                                                The Obligor fails to make a repayment or payment of principal, interest or other amount in respect of the Sycamore Facility on the date it was due to be paid; or
                                            </li>

                                            <li>
                                                The Obligor breaches any of the terms and conditions of the Sycamore Facility including any representation or confirmation given by the Obligor in this Sycamore Facility agreement.
                                            </li>

                                            <li>
                                                Where a bankruptcy petition is filed against the Obligor.
                                            </li>

                                            <li>
                                                Where the Obligor is unable to pay any other party within the meaning of Section 1 of the Bankruptcy Act (Cap 30) Laws of the Federation of Nigeria.
                                            </li>

                                            <li>
                                                Where a situation arises which in the opinion of Sycamore makes it inappropriate to continue to extend the facility to the Obligor.
                                            </li>

                                            <li>
                                                Where the Obligor defaults in the performance or observance of any other term, condition or covenant herein and such breach or default shall continue unremedied after ten days’ notice shall have been given to the Obligor.
                                            </li>
                                        </ol>
                                    </li>

                                    <li>
                                        <h3 class="text-md font-semibold pt-6 pb-2">Costs and Expenses</h3>
                                        <ol class="ml-6 list-outside list-decimal space-y-3">
                                            <li>
                                                The Obligor hereby agrees to pay all administrative costs, charges and expenses (including legal fees) reasonably incurred by Sycamore in connection with the processing of this Sycamore Facility request.
                                            </li>

                                            <li>
                                                The Obligor hereby agrees to pay all costs and expenses (including legal fees) incurred by in connection with any proceedings instituted by or against Sycamore as a consequence of entering into this Sycamore Facility agreement.
                                            </li>
                                        </ol>
                                    </li>

                                    <li>
                                        <h3 class="text-md font-semibold pt-6 pb-2">Assignment and Disclosure of Information</h3>
                                        <ol class="ml-6 list-outside list-decimal space-y-3">
                                            <li>
                                                The Obligor consents irrevocably to any future transfer and assignment, however arising of the Sycamore Facility, whether as part of a Sycamore Facility transfer scheme or otherwise.
                                            </li>

                                            <li>
                                                The Obligor authorizes Sycamore to disclose any information or documentation relating to the Sycamore Facility to third parties including credit reference agencies, collection agencies, as well as the Obligor’s employers (where the Obligor is in salaried employment) in the event that the Sycamore Facility has ceased to be serviced by the Obligor.
                                            </li>
                                        </ol>
                                    </li> 

                                    <li>
                                        <h3 class="text-md font-semibold pt-6 pb-2">Variation of Conditions</h3>
                                        <ol class="ml-6 list-outside list-decimal space-y-3">
                                            <li>
                                                Sycamore reserves the right at all times to vary the terms and conditions of the Sycamore Facility agreement. Any such variation will become effective upon notice to the Obligor by any means Sycamore Integrated Services considers reasonable in the circumstance.
                                            </li>
                                        </ol>
                                    </li>   

                                    <li>
                                        <h3 class="text-md font-semibold pt-6 pb-2">Set-Off</h3>
                                        <ol class="ml-6 list-outside list-decimal space-y-3">
                                            <li>
                                                Sycamore may at any time with or without notice (a) combine or consolidate some or all of the Obligor’s accounts with it without any liability to Sycamore (b) set off and transfer any sum standing to the credit of any such account in full or partial payment of any amount the Obligor owes to the Company.
                                            </li>

                                            <li>
                                                This clause is in addition to and does not amend or qualify any other present or future right of Sycamore Integrated Services to combine or set off any of the Obligor’s accounts with it.
                                            </li>
                                        </ol>
                                    </li>  

                                    <li>
                                        <h3 class="text-md font-semibold pt-6 pb-2">Indemnity</h3>
                                        <p class="leading-6">
                                            The Obligor agrees to fully indemnify Sycamore against all costs and expenses (including legal fees, collection commission, insurance cover e.t.c) arising in any way in connection to the Obligor’s facility, in enforcing these terms and conditions or in recovering any amounts due to Sycamore or incurred by Sycamore in any legal proceedings of whatever nature.
                                        </p>
                                    </li>  


                                    <li>
                                        <h3 class="text-md font-semibold pt-6 pb-2">Complaint/Claim</h3>
                                        <p class="leading-6">
                                            The Obligor specifically and unequivocally waives any right to contest, challenge, protest or claim upon any subsequent amendments made by Sycamore to the terms of this facility or any notification sent by way of e-mail or text message to the Obligor’s e-mail address or mobile phone.
                                        </p>
                                    </li>  

                                    <li>
                                        <h3 class="text-md font-semibold pt-6 pb-2">Collateral</h3>
                                        <ol class="ml-6 list-outside list-decimal space-y-3">
                                            <li>
                                                Sycamore reserves the right to liquidate collateral collected for the purpose of securitizing the loan facility once repayment has fallen past its due date. In the event that a cheque was offered as a form of soft security, Sycamore reserves the right to present the cheque once a default has occurred. Furthermore, note that the issuance of a dud cheque would be considered as fraudulent and <strong>WILL RESULT IN THE FULL EXTENT OF THE LAW BEING USED AGAINST YOU TO ENSURE THAT YOU ARE PENALIZED FOR YOUR CRIMES.</strong>
                                            </li>
                                        </ol>
                                    </li>  

                                    <li>
                                        <h3 class="text-md font-semibold pt-6 pb-2">Governing Law</h3>
                                        <ol class="ml-6 list-outside list-decimal space-y-3">
                                            <li>
                                                This Sycamore Facility agreement is governed by Nigerian law and the courts of the Federal Republic of Nigeria have jurisdiction in any matter arising from it.
                                            </li> 
                                        </ol>
                                    </li>

                                    <li>
                                        <h3 class="text-md font-semibold pt-6 pb-2">Consent</h3>
                                        <ol class="ml-6 list-outside list-decimal space-y-3">
                                            <li>
                                                The Obligor confirms that he/she has read, understood and agreed to the above terms and conditions. By using this service the Obligor indicates that he/she unconditionally accepts the terms of this agreement and agrees to abide by these terms. Until all financial obligations are fully fulfilled, this agreement is still in effect.
                                            </li>
                                            <li>
                                                The Obligor accepts and consent to proceeding in the application of this facility.
                                            </li>
                                        </ol>
                                    </li>
                                </ol>

                                <ul class="mt-4 text-xs lg:text-sm">
                                    <li>
                                        <strong>
                                            **This offer is subject to a satisfactory credit report and further verification checks in event that your application is unsuccessful, your documents shall be returned to you.**
                                        </strong>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="pb-6 space-y-3">
                            <h3 class="text-sm font-semibold">DISCLAIMER:</h3>
                            <p>
                                <i>As required by law, Sycamore Integrated Services is mandated to disclose the status of defaulting Sycamore Facility to relevant local or international Credit Bureaus, Credit Monitoring Agencies and Regulatory Bodies.</i>
                            </p>
                        </div>

                        <div class="flex flex-col md:flex-row md:justify-between md:items-end pb-12">
                            <div>
                                <p class="pb-1" :class="this.loan.contract_status != 'generated' ? 'pb-6' : ''">
                                    Thank you. <br /> For Sycamore Integrated Solutions Limited
                                </p>
                                <p v-if="this.loan.contract_status != 'generated'" class="pb-3">
                                    <span class="text-3xl font-Allura">
                                        {{ loan.Customer ? loan.Customer.name : ''}}
                                    </span>
                                    <br />
                                    <span class="text-sm">
                                        {{moment().format('MMMM Do YYYY, h:mm a')}}
                                    </span>
                                    <br />
                                    <span class="text-xs">
                                        Contract {{ loan ? loan.contract_status : '' }}
                                    </span>
                                </p>
                                <p>
                                    Yours faithfully, <br> For Sycamore.
                                </p>
                            </div>

                            <div
                                class="flex pt-6 pr-0 md:pr-6 space-x-3"
                                v-if="this.token && this.loan.contract_status == 'generated'"
                            >
                                <button
                                    type="button"
                                    @click="(decision = 'accept'), (isComponentModalActive = true)"
                                    :class="loadingStatus ? 'is-loading' : ''"
                                    :disabled="loadingStatus ? true : false"
                                    class="bg-green-500 rounded-sm py-2 px-6 text-white border border-green-600"
                                >
                                    Accept
                                </button>

                                <button
                                    type="button"
                                    @click="(decision = 'reject'), (isComponentModalActive = true)"
                                    :class="loadingStatus ? 'is-loading' : ''"
                                    :disabled="loadingStatus ? true : false"
                                    class="bg-red-500 rounded-sm py-2 px-6 text-white border border-red-600"
                                >
                                    Reject
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div v-if="activeTab == 1">
                <TokenInvalid />
            </div>
        </div>

        <b-modal
            v-model="isComponentModalActive"
            has-modal-card
            trap-focus
            aria-modal
            >
            <template #default="props">
                <transactionPassword
                :data="{
                    title: `${
                    decision.charAt(0).toUpperCase() + decision.slice(1)
                    } contract`,
                    subtitle: 'Please provide your password to continue',
                    buttonLabel: decision,
                }"
                :loading="loadingStatus"
                @callback="fetchDecideContract"
                @close="props.close"
                />
            </template>
        </b-modal>
    </div>
</template>

<script>
import moment from "moment";
import Ripple from "@/components/Ripple";
import TokenInvalid from "@/components/TokenInvalid";
import transactionPassword from "@/components/transactionPassword";
import { fetchLoan, decideContract } from "@/api/loan";

export default {
    name: "Loan",

    components: {
        Ripple,
        transactionPassword,
        TokenInvalid
    },

    data() {
        return {
            loan: {},
            repayment_schedules: [],
            token: "",
            decision: "",
            password: "",
            loadingStatus: false,
            isComponentModalActive: false,
            activeTab: 0,

            errorStatus: [],
        }
    },

    computed: {
        total_interest() {
            let total = this.repayment_schedules.map(item => item.interestTobePaid).reduce((prev, curr) => prev + curr, 0);
            return total;
        },

        total_principal() {
            let total = this.repayment_schedules.map(item => item.principalTobePaid).reduce((prev, curr) => prev + curr, 0);
            return total;
        },

        maturity_date() {
            return this.loan.disburement_date ? moment(`${this.loan.disburement_date}`).add(this.loan.duration, `${this.loan.duration_type}`) : ""
        },

        first_schedule() {
            return this.repayment_schedules[0];
        },

        // totalDeductions() {
        //     return this.loan.DisbursementDeductions.reduce((total, deduction) => {
        //         return total + deduction.amount;
        //     }, 0);
        // }
    },

    methods:{
        moment: function (date) {
            return moment(date);
        },

        getLoan(){
            this.loadingStatus = true;
            const {offer_link} = this.$route.params

            fetchLoan(offer_link)

            .then((res)=>{
                let {loan, schedules} = res.data.data
                this.loan = loan
                this.repayment_schedules = schedules
                this.activeTab = 0
            })
            
            .catch((error)=>{
                this.activeTab = 1
                this.errorStatus = error.res;
            })

            .finally(() => {
                this.loadingStatus = false
            })
        },

        fetchDecideContract (password) {
            this.loadingStatus = true
            const {token} = this.$route.query;
            const body = { 
                loan_id: this.loan.loan_id, 
                status: `${this.decision}ed`,
                password: password
            }

            decideContract(body, token)

            .then((res) => {
                this.$toast.success(`Contract ${ this.decision }ed Successfully`, {
                    timeout: 3000
                });
                this.isComponentModalActive = false
                this.getLoan()
            })

            .catch((error) => {
                this.$toast.error(error.response.data.message.errors[0].msg,
                 {
                    timeout: 5000
                });
            }) 
            
            .finally(() => {
                this.loadingStatus = false
            })
        }
    },

    mounted() {
        this.getLoan();
    },
    
    created(){
        this.token = this.$route.query.token;
    }
}
</script>

<style scoped>
    .list-style-alpha {
        list-style-type: lower-alpha;
    }
    .list-style-roman {
        list-style-type: lower-roman; /* Use upper-roman for uppercase */
    }
</style>