<template>
  <div class="bg-gray-100 w-screen h-screen flex justify-center items-center px-5 lg:px-10">
    <div class="flex flex-col items-center justify-center bg-white shadow-lg p-7 rounded-xl py-10">
      <img src="@/assets/images/logo.png" width="120px" />
      <div class="flex flex-col items-center justify-center" v-if="!loadingStatus">
        <p class="mt-10 mb-5 text-base text-center" v-if="verifyComplete">
          You have successfully verified your account with email address<br/>
          <span class="text-gray-500">{{confirmation_token && confirmation_token.email}}</span>
        </p>
        <p class="mt-10 mb-5 text-base text-center" v-else-if="!confirmation_token">
            The session is expired or invalid
        </p>
        <p class="mt-10 mb-5 text-base text-center" v-else>
          We could not verify this account. If you are experiencing issues,<br /> kindly contact us on
          <a href="https://sycamore.ng" class="text-success">our website.</a>
        </p>
        <!-- <button class="bg-success text-white font-bold px-5 py-2 rounded-md">
          Proceed to login
        </button> -->
      </div>
      <div v-else>
        <loader />
      </div>
    </div>
  </div>
</template>

<script>
import {verifyToken, verifyEmail} from '@/api/auth';
import Loader from '@/svg/Loader.vue';

export default {
  name: 'Verify',
  components: { Loader },
  data() {
    return {
      confirmation_token: null,
      loadingStatus: false,
      verifyComplete: false
    }
  },
  methods: {
    async verifyTokenValidity() {
      try {
        this.loadingStatus = true;
        const {confirmation_token} = this.$route.query;
        if(!confirmation_token) {
          this.confirmation_token = null;
          return;
        }
        const response = await verifyToken({
          userToken: confirmation_token,
        })
        this.loadingStatus = false;
        if (response.data?.success) {
          this.confirmation_token = response.data?.data;
          this.verifyAccount();
        } else {
          this.confirmation_token = null;
        }
      } catch (err) {
        this.loadingStatus = false;
      }
    },
    async verifyAccount() {
      try {
        this.loadingStatus = true;
        const {confirmation_token} = this.$route.query;
        if(!confirmation_token) {
          this.confirmation_token = null;
          return;
        }
        const response = await verifyEmail({
          confirmationToken: confirmation_token,
        })
        this.loadingStatus = false;
        if (response.data?.success) {
          this.verifyComplete = true;
        } else {
          this.confirmation_token = null;
          this.verifyComplete = false;
        }
      } catch (err) {
        this.loadingStatus = false;
      }
    }
  },
  mounted() {
    this.verifyTokenValidity();
  }
}
</script>
