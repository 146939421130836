<template>
    <div
        class="w-screen h-screen flex justify-center items-center"
        id="wallet-body"
    >
        <div class="card py-3" style="border-radius: 8px; width: 32rem">
            <div class="card-content">
                <div class="columns is-multiline tw-items-center">
                    <div class="column is-12">
                        <div class="flex justify-center mb-4">
                            <img src="@/assets/images/logo_icon.svg" />
                        </div>

                        <div class="text-center text-black">
                            <p class="text-base font-semibold pb-1">
                                {{ responseStatus == null ? 'Download Account Statement' : responseStatus == 200 ? 'Downloaded successfully!': 'Access Denied' }}
                            </p> 
                            <p class="text-xs">
                                {{ responseStatus == null ? 'Click the button below to download your account statement' : responseStatus == 200 ? 'Your sycamore wallet statement is now on your device': 'The session is expired or invalid' }}
                                
                            </p>
                        </div>
                    </div>

                    <div v-if="responseStatus == null || responseStatus == 200" class="column is-12">
                        <div class="mx-24 is-centered">
                            <button
                                @click="getStatement()"
                                type="button"
                                :class="isLoading ? 'is-loading' : ''"
                                class="button is-primary is-fullwidth"
                                :disabled="isLoading ? true : false"
                            >
                                Download
                            </button>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { fetchStatement } from '@/api/statement';

    export default {
        data() {
            return {
                isLoading: false,
                statement: null,
                responseStatus: null,
                lordIconColor: 'primary:#26c165',
            }
        },

        methods: {
            async getStatement() {
                this.isLoading = true;

                try {
                    const { token } = this.$route.params
                    let response = await fetchStatement(token)

                    if (response.status === 200) {
                        this.download(response.data, 'Your Statement.pdf');
                    }

                    this.responseStatus = response.status

                } catch (error) {
                    this.responseStatus = error.response.status

                } finally {
                    this.isLoading = false;
                }
            },

            download(data, name) {
                const fileUrl = window.URL.createObjectURL(new Blob([data]));

                const link = document.createElement('a');
                link.href = fileUrl;
                link.setAttribute('download', name);
                document.body.appendChild(link);
                link.click();
            }
        },

        mounted() {
        }
    }
</script>

<style scoped>
#wallet-body {
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  background: radial-gradient(100% 234.68% at 0% 100%, #F4FFF9 22.06%, #F4FFF9 88.31%);
}

.button {
    height: 3.1rem;
    border-radius: 10px;
}
</style>